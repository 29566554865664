export default function () {
  return {
    breakpoints: {
      1: {
        spaceBetween: 8,
        slidesOffsetBefore: 16,
      },
      375: {
        spaceBetween: 8,
        slidesOffsetBefore: 16,
      },
      768: {
        spaceBetween: 16,
        slidesOffsetBefore: 40,
      },
      1024: {
        spaceBetween: 24,
        slidesOffsetBefore: 112,
        slidesOffsetAfter: 112,
      },
      1025: {
        spaceBetween: 24,
        slidesOffsetBefore: 112,
        slidesOffsetAfter: 112,
      },
    },
  };
}
