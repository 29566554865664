<template>
  <swiper
    :id="swiperId"
    :breakpoints="breakpoints"
    :modules="modules"
    :navigation="enableNav && !isXs"
    :class="[isHovering && !hasModal ? '!z-[70]' : 'z-[40]', carouselClass]"
    ref="swiperRef"
    slides-per-view="auto"
    @swiper="(s) => (swiperEl = s)"
    @reach-beginning="reachedBeginning = true"
    @reach-end="reachedEnd = true"
    @mouseenter="setHovering(true)"
    @mouseleave="setHovering(false)"
    @slide-change="onSlideChange"
    @touch-move="onTouchMove"
    @touch-end="onTouchEnd"
  >
    <swiper-slide
      v-for="(item, key) in items"
      :class="{ 'z-30': isHovered != `${swiperId}-floater-${key}` }"
      class="max-w-min cursor-pointer"
      @mouseenter="startTimer(`${swiperId}-floater-${key}`)"
      @mouseleave="resetTimer(`${swiperId}-floater-${key}`)"
    >
      <!-- expose the item, let the parent define what to render -->

      <slot
        :item="item"
        :key="key"
        :is-hovered="isHovered"
        :carousel-id="swiperId"
        name="slider-item"
      ></slot>
    </swiper-slide>
  </swiper>
</template>

<!-- carousel component but using Swiper Element -->
<script setup>
import { useInterval, useResizeObserver } from "@vueuse/core";
import { Navigation } from "swiper/modules";
import { PLAYERS } from "@/constants";

const props = defineProps({
  elementId: String,
  items: {
    type: Array,
    required: true,
  },
  navigatorClass: {
    type: String,
    default: () => null,
  },
  overrideOptions: {
    type: Object,
    default: () => null,
  },
  enableNav: {
    type: Boolean,
    default: () => true,
  },
  carouselClass: String,
});

const emit = defineEmits([
  "onSliderMove",
  "isDragging",
  "onHover",
  "onSlideChange",
]);

const isHovering = ref(false);
// container for the slide that's being hovered on
const isHovered = ref();
const reachedBeginning = ref(true);
const reachedEnd = ref(false);
const swiperEl = ref();
const timerRef = ref();

const route = useRoute();

const hoveredElement = ref();

const { isXs } = useBreakpoints();
const swiperId = computed(() => useKebabCase(props.elementId));
const modules = computed(() => [Navigation]);
const carouselOptions = useCarouselOptions();
const breakpoints = computed(() => {
  return {
    ...carouselOptions.breakpoints,
    ...props.overrideOptions?.breakpoints,
  };
});

const hasModal = computed(() => {
  return Object.keys(route.query).includes("media");
});

const onSlideChange = ({ activeIndex }) => {
  emit("onSlideChange", activeIndex);
  if (activeIndex > 0) {
    reachedBeginning.value = false;
  }

  if (activeIndex <= props.items.length) {
    reachedEnd.value = false;
  }
};

const onTouchMove = () => emit("isDragging", true);
const onTouchEnd = () => emit("isDragging", false);

const setHovering = (value) => {
  isHovering.value = value;
  emit("onHover", value ? props.elementId : null);
};

const startTimer = (slideId) => {
  hoveredElement.value = slideId;

  timerRef.value = useInterval(10, { controls: true });
};

const resetTimer = () => {
  hoveredElement.value = null;
  isHovered.value = null;
  timerRef.value?.reset();
  timerRef.value?.pause();

  useAllPlayers().getStore(PLAYERS.FLOATER_PLAYER)?.$reset();
};

watch(
  () => timerRef.value?.counter,
  (newValue) => {
    if (newValue >= 30) {
      timerRef.value.pause();
      isHovered.value = hoveredElement.value;
    }
  }
);
</script>

<style lang="scss" scoped>
:deep(.swiper-button-disabled) {
  display: none;
}
</style>
